import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  Stack,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Format from "../../Format";
import { pick, keys } from "lodash";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { toCapitalize, sleep } from "utils";

import { Help } from "../../Widgets";

// hooks
import useMeetGreet from "hooks/useMeetGreet";

// import fields
import {
  ToggleButtonGroupField,
  AutocompleteField,
  DatePickerField,
  CounterField,
} from "../../FormFields";

// import data
import services from "data/services.json";
import pax from "data/pax.json";
import packages from "data/packages.json";

// forms
import { mgWidgetFormModel } from "../FormModel/formModel";
import { mgWidgetFormInitialValues } from "../FormModel/formInitialValues";

const { formId, formField } = mgWidgetFormModel;

// validation schema
const minDate = new Date(new Date().getTime() + 86400000);
const validationSchema = Yup.object().shape({
  flightDate: Yup.date().min(new Date(), "Please choose future date"),
});

const MeetGreetWidgetForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { service, packageName, flightDate } = formField;
  const { mgValues, setmgValues } = useMeetGreet();

  useEffect(() => {
    if (mgValues.saved === 1) {
      navigate("/meetgreet", { replace: true });
    }
  }, [mgValues, navigate]);

  // initialvalues
  const initialValues = pick(mgValues, keys(mgWidgetFormInitialValues));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async () => {
        setLoading(true);
        setmgValues("basket", 1);
        await sleep(1000);

        if (location.pathname === "/meetgreet") {
          navigate(0);
        } else {
          navigate("/meetgreet", { replace: true });
        }
      }}
    >
      <Form id={formId}>
        <Card sx={{ width: { xs: 365, sm: 420 } }}>
          <CardContent>
            <Typography
              variant="h5"
              component="div"
              sx={{ fontSize: { xs: "20px" }, fontWeight: "bold" }}
            >
              Airport Meet & Greet Services
            </Typography>

            <Typography sx={{ my: 1.5 }} color="text.secondary">
              select and book your service
            </Typography>

            <ToggleButtonGroupField
              name={service.name}
              label={service.label}
              value={mgValues.service}
              setContextValue={setmgValues}
              options={services}
              fullWidth
            />

            <Grid container spacing={2} sx={{ my: 2 }}>
              <Grid item xs={6}>
                <AutocompleteField
                  data={packages}
                  size="small"
                  contextvalue={mgValues.packageName}
                  name={packageName.name}
                  label={`${toCapitalize(mgValues.service)} ${
                    packageName.label
                  }`}
                  setContextValue={setmgValues}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <DatePickerField
                  size="small"
                  disablePast
                  minDate={minDate}
                  name={flightDate.name}
                  label={flightDate.label}
                  inputFormat="dd/MM/yyyy"
                  value={mgValues.flightDate}
                  setContextValue={setmgValues}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <CounterField
                  options={pax}
                  values={mgValues}
                  updateValues={setmgValues}
                />
              </Grid>
            </Grid>
            {mgValues.total > 0 && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{ mb: 2 }}
              >
                {mgValues.cid === 0 && (
                  <>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 700,
                        fontSize: "18px",
                        color: "#f50057",
                      }}
                    >
                      Total:
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 700,
                        fontSize: "18px",
                        color: "#f50057",
                      }}
                    >
                      <Format
                        value={mgValues.total}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "0.7rem",
                        my: 1,
                        color: "secondary.main",
                      }}
                    >
                      {"inc. VAT"}
                    </Typography>
                  </>
                )}
              </Stack>
            )}
            <LoadingButton
              loading={loading}
              fullWidth
              variant="contained"
              type={mgValues.total > 0 ? "submit" : "button"}
            >
              Book Now
            </LoadingButton>
          </CardContent>
          <CardActions
            sx={{ display: "flex", justifyContent: "center", pb: 3 }}
          >
            <Help
              telephone={true.toString()}
              color="primary.main"
              sx={{ fontSize: "75%" }}
            />
          </CardActions>
        </Card>
      </Form>
    </Formik>
  );
};

export default MeetGreetWidgetForm;
